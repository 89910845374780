// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArArticleTile-module--ar-h1-mobile--2Pu3m";
export var arH2Mobile = "ArArticleTile-module--ar-h2-mobile--6kPm_";
export var arH3Mobile = "ArArticleTile-module--ar-h3-mobile--3euLU";
export var arH4Mobile = "ArArticleTile-module--ar-h4-mobile--15mGA";
export var arH5Mobile = "ArArticleTile-module--ar-h5-mobile--1ZqT5";
export var arH1 = "ArArticleTile-module--ar-h1--1vSP0";
export var arH2 = "ArArticleTile-module--ar-h2--2NGB1";
export var arH3 = "ArArticleTile-module--ar-h3--2DKMA";
export var arH4 = "ArArticleTile-module--ar-h4--2jFcy";
export var arH5 = "ArArticleTile-module--ar-h5--1F0nq";
export var arParagraphXl = "ArArticleTile-module--ar-paragraph-xl--3wQ65";
export var arParagraphXlMedium = "ArArticleTile-module--ar-paragraph-xl-medium--TwfjZ";
export var arParagraphBase = "ArArticleTile-module--ar-paragraph-base--fjPS2";
export var arParagraphBaseMedium = "ArArticleTile-module--ar-paragraph-base-medium--2zkJl";
export var arParagraphSmall = "ArArticleTile-module--ar-paragraph-small--3-jR8";
export var arParagraphSmallMedium = "ArArticleTile-module--ar-paragraph-small-medium--3xYwP";
export var arParagraphXsmall = "ArArticleTile-module--ar-paragraph-xsmall--1DAFH";
export var arParagraphXsmallMedium = "ArArticleTile-module--ar-paragraph-xsmall-medium--hnVkb";
export var arLinkMobile = "ArArticleTile-module--ar-link-mobile--TSEsa";
export var arLinkDesktop = "ArArticleTile-module--ar-link-desktop--3glml";
export var arQuote = "ArArticleTile-module--ar-quote--6u4Lj";
export var arQuoteMobile = "ArArticleTile-module--ar-quote-mobile--2wYYL";
export var arGradientLight = "ArArticleTile-module--ar-gradient-light--L7xpc";
export var arGradientBlue = "ArArticleTile-module--ar-gradient-blue--2OX32";
export var arTransition = "ArArticleTile-module--ar-transition--3cGO4";
export var article = "ArArticleTile-module--article--3VlQq";
export var themeWhite = "ArArticleTile-module--themeWhite--1JDd4";
export var themeGray = "ArArticleTile-module--themeGray--2rGQ-";
export var categories = "ArArticleTile-module--categories--3437M";
export var authors = "ArArticleTile-module--authors--1gTOk";