// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArSearchInput-module--ar-h1-mobile--1D6cG";
export var arH2Mobile = "ArSearchInput-module--ar-h2-mobile--3vcoo";
export var arH3Mobile = "ArSearchInput-module--ar-h3-mobile--iqYpG";
export var arH4Mobile = "ArSearchInput-module--ar-h4-mobile--1leH-";
export var arH5Mobile = "ArSearchInput-module--ar-h5-mobile--1vwaj";
export var arH1 = "ArSearchInput-module--ar-h1--P2wJW";
export var arH2 = "ArSearchInput-module--ar-h2--2ZxJj";
export var arH3 = "ArSearchInput-module--ar-h3--1Zh5H";
export var arH4 = "ArSearchInput-module--ar-h4--1dud9";
export var arH5 = "ArSearchInput-module--ar-h5--1R1oA";
export var arParagraphXl = "ArSearchInput-module--ar-paragraph-xl--1j83T";
export var arParagraphXlMedium = "ArSearchInput-module--ar-paragraph-xl-medium--2MoGY";
export var arParagraphBase = "ArSearchInput-module--ar-paragraph-base--2CJd3";
export var arParagraphBaseMedium = "ArSearchInput-module--ar-paragraph-base-medium--3un0X";
export var arParagraphSmall = "ArSearchInput-module--ar-paragraph-small--2KIhO";
export var arParagraphSmallMedium = "ArSearchInput-module--ar-paragraph-small-medium--1Gkuh";
export var arParagraphXsmall = "ArSearchInput-module--ar-paragraph-xsmall--3VGfG";
export var arParagraphXsmallMedium = "ArSearchInput-module--ar-paragraph-xsmall-medium--1RWb7";
export var arLinkMobile = "ArSearchInput-module--ar-link-mobile--1z-F-";
export var arLinkDesktop = "ArSearchInput-module--ar-link-desktop--2X-lP";
export var arQuote = "ArSearchInput-module--ar-quote--3xsqz";
export var arQuoteMobile = "ArSearchInput-module--ar-quote-mobile--6suQ2";
export var arGradientLight = "ArSearchInput-module--ar-gradient-light--GfvFn";
export var arGradientBlue = "ArSearchInput-module--ar-gradient-blue--2D5jb";
export var arTransition = "ArSearchInput-module--ar-transition--m2Gki";
export var container = "ArSearchInput-module--container--1973_";
export var searchIcon = "ArSearchInput-module--searchIcon--1qG9k";
export var crossIcon = "ArSearchInput-module--crossIcon--_CUSf";