// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArTag-module--ar-h1-mobile--1Yplr";
export var arH2Mobile = "ArTag-module--ar-h2-mobile--3peHI";
export var arH3Mobile = "ArTag-module--ar-h3-mobile--1J6JM";
export var arH4Mobile = "ArTag-module--ar-h4-mobile--1dX5g";
export var arH5Mobile = "ArTag-module--ar-h5-mobile--18fq3";
export var arH1 = "ArTag-module--ar-h1--zjGju";
export var arH2 = "ArTag-module--ar-h2--HtevY";
export var arH3 = "ArTag-module--ar-h3--3WUXV";
export var arH4 = "ArTag-module--ar-h4--OvqU_";
export var arH5 = "ArTag-module--ar-h5--1aTKy";
export var arParagraphXl = "ArTag-module--ar-paragraph-xl--XSIuF";
export var arParagraphXlMedium = "ArTag-module--ar-paragraph-xl-medium--1TmNz";
export var arParagraphBase = "ArTag-module--ar-paragraph-base--1_N0_";
export var arParagraphBaseMedium = "ArTag-module--ar-paragraph-base-medium--1073w";
export var arParagraphSmall = "ArTag-module--ar-paragraph-small--1Nezs";
export var arParagraphSmallMedium = "ArTag-module--ar-paragraph-small-medium--hku1e";
export var arParagraphXsmall = "ArTag-module--ar-paragraph-xsmall--cslmR";
export var arParagraphXsmallMedium = "ArTag-module--ar-paragraph-xsmall-medium--3xwGc";
export var arLinkMobile = "ArTag-module--ar-link-mobile--3bfme";
export var arLinkDesktop = "ArTag-module--ar-link-desktop--2I6hB";
export var arQuote = "ArTag-module--ar-quote--2sd7I";
export var arQuoteMobile = "ArTag-module--ar-quote-mobile--3Q1Sz";
export var arGradientLight = "ArTag-module--ar-gradient-light--1TPeq";
export var arGradientBlue = "ArTag-module--ar-gradient-blue--2QcZO";
export var arTransition = "ArTag-module--ar-transition--2tCK0";
export var container = "ArTag-module--container--3mmTN";
export var small = "ArTag-module--small--13cry";
export var themeRed = "ArTag-module--themeRed--1MnSd";
export var themeBlue = "ArTag-module--themeBlue--mRask";
export var themeGray = "ArTag-module--themeGray--3VRj6";