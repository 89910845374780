// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArBlogHeader-module--ar-h1-mobile--cQbHV";
export var arH2Mobile = "ArBlogHeader-module--ar-h2-mobile--3_0r7";
export var arH3Mobile = "ArBlogHeader-module--ar-h3-mobile--2RHtT";
export var arH4Mobile = "ArBlogHeader-module--ar-h4-mobile--2n8Tn";
export var arH5Mobile = "ArBlogHeader-module--ar-h5-mobile--1LHa8";
export var arH1 = "ArBlogHeader-module--ar-h1--1GCIK";
export var arH2 = "ArBlogHeader-module--ar-h2--3NOQa";
export var arH3 = "ArBlogHeader-module--ar-h3--7INEe";
export var arH4 = "ArBlogHeader-module--ar-h4--1ccGR";
export var arH5 = "ArBlogHeader-module--ar-h5--3rU07";
export var arParagraphXl = "ArBlogHeader-module--ar-paragraph-xl--3SN_k";
export var arParagraphXlMedium = "ArBlogHeader-module--ar-paragraph-xl-medium--1vE9_";
export var arParagraphBase = "ArBlogHeader-module--ar-paragraph-base--2NAvN";
export var arParagraphBaseMedium = "ArBlogHeader-module--ar-paragraph-base-medium--IJL-e";
export var arParagraphSmall = "ArBlogHeader-module--ar-paragraph-small--QC028";
export var arParagraphSmallMedium = "ArBlogHeader-module--ar-paragraph-small-medium--2Z137";
export var arParagraphXsmall = "ArBlogHeader-module--ar-paragraph-xsmall--2U3iy";
export var arParagraphXsmallMedium = "ArBlogHeader-module--ar-paragraph-xsmall-medium--26XAL";
export var arLinkMobile = "ArBlogHeader-module--ar-link-mobile--2HWtb";
export var arLinkDesktop = "ArBlogHeader-module--ar-link-desktop--LQUx_";
export var arQuote = "ArBlogHeader-module--ar-quote--yF0CP";
export var arQuoteMobile = "ArBlogHeader-module--ar-quote-mobile--1StYe";
export var arGradientLight = "ArBlogHeader-module--ar-gradient-light--1M_kQ";
export var arGradientBlue = "ArBlogHeader-module--ar-gradient-blue--3bU3b";
export var arTransition = "ArBlogHeader-module--ar-transition--1oFiC";
export var container = "ArBlogHeader-module--container--31Hnj";
export var categories = "ArBlogHeader-module--categories--2ZJNL";
export var authors = "ArBlogHeader-module--authors--Cl2aJ";